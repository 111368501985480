@use "../global" as global;

.p-price__contents {
  @include global.responsive(md) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.p-price__image {
  @include global.responsive(md) {
    width: 35%;
  }
}

.p-price__info {
  padding: 80px 15px;
  background-color: var(--bg-secondary);
  @include global.responsive(md) {
    padding: 140px 40px;
    width: 65%;
  }
  @include global.responsive(xxl) {
    padding: 140px calc((100vw - 1230px) / 2) 140px 100px;
  }
}

.p-price__info__text {
  color: var(--color-white);
  line-height: var(--line-height-base);
  margin-bottom: 40px;
  @include global.responsive(md) {
    margin-bottom: 60px;
    line-height: var(--line-height-large);
  }
}


// price 下層ページ

.p-price--simulation {
  padding-bottom: 80px;
  @include global.responsive(md) {
    padding-bottom: 100px;
  }
}

.p-price__about {
  border: 2px solid var(--color-primary);
  margin-top: 80px;
  @include global.responsive(lg) {
    margin-top: 100px;
  }
}

.p-price__about__title {
  font-family: var(--font-family-serif);
  font-size: global.$font-size-xl;
  color: var(--color-white);
  background-color: var(--color-primary);
  padding: 20px;
  @include global.responsive(md) {
    padding: 20px 60px;
  }
}

.p-price__about__contents {
  background-color: var(--color-white);
  padding: 20px;
  @include global.responsive(md) {
    padding: 60px;
  }
}

.p-price__about__contents__details {
  margin-bottom: 30px;
  border: 1px solid var(--color-primary);
  padding: 30px 10px;
  @include global.responsive(md) {
    margin-bottom: 40px;
    padding: 60px;
  }
}

.p-price__about__contents__details__list {
  max-width: 500px;
  margin: 0 auto;
}

.p-price__about__contents__details__item {
  font-family: var(--font-family-serif);
  font-size: global.$font-size-lg;
  color: var(--color-primary);
  margin-bottom: 10px;
  @include global.responsive(md) {
    margin-bottom: 20px;
  }
}

.p-price__about__contents__details__item.--plus {
  padding-left: 14px;
} 

.p-price__about__contents__details__item:last-of-type {
  margin-bottom: 0;
}

.p-price__about__contents__details__item span {
  font-family: var(--font-family-serif);
  font-size: global.$font-size-md;
  color: var(--color-primary);
  padding: 10px 14px;
  border: 1px solid var(--color-primary);
  margin-right: 15px;
}

.p-price__about__contents__section + .p-price__about__contents__section {
  margin-top: 80px;
  @include global.responsive(lg) {
    margin-top: 100px;
  }
}

.p-price__about__contents__title {
  position: relative;
  font-family: var(--font-family-serif);
  font-size: global.$font-size-lg;
  color: var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
  padding-left: 40px;
  padding-bottom: 15px;
  margin-bottom: 10px;
  @include global.responsive(md) {
    padding-left: 50px;
    margin-bottom: 20px;
  }
}

.p-price__about__contents__title::before {
  position: absolute;
  font-size: global.$font-size-sm;
  color: var(--color-primary);
  padding: 4px 9px 4px 10px;
  top: 0;
  left: 0;
  border: 1px solid var(--color-primary);
  @include global.responsive(md) {
    top: 4px;
  }
}

.p-price__about__contents__title.-one::before {
  content: "1";
}

.p-price__about__contents__title.-two::before {
  content: "2";
}

.p-price__about__contents__title.-three::before {
  content: "3";
}

.p-price__about__contents__title span {
  font-family: var(--font-family-serif);
  font-size: global.$font-size-md;
  color: var(--color-primary);
  padding: 10px 14px;
  border: 1px solid var(--color-primary);
  margin-right: 15px;
}

.p-price__about__contents__table {
  margin-top: 30px;
  @include global.responsive(lg) {
    display: flex;
    column-gap: 30px;
  }
}

.p-price__about__contents__table__item {
  border: 1px solid var(--color-primary);
}

.p-price__about__contents__table__item:not(:last-of-type) {
  margin-bottom: 30px;
  @include global.responsive(lg) {
    margin-bottom: 0;
  }
}

.p-price__about__contents__table.--two .p-price__about__contents__table__item {
  width: 100%;
  @include global.responsive(lg) {
    width: calc(50% - 15px);
  }
}

.p-price__about__contents__table.--three .p-price__about__contents__table__item {
  width: 100%;
  @include global.responsive(lg) {
    width: calc(33.3333% - 26.6666px);
  }
}

.p-price__about__contents__table__item__title {
  background-color: var(--color-primary);
  padding: 20px 10px;
  @include global.responsive(md) {
    padding: 20px;
  }
}

.p-price__about__contents__table__item__title h3 {
  font-family: var(--font-family-serif);
  font-size: global.$font-size-lg;
  color: var(--color-white);
  margin-bottom: 15px;
  text-align: center;
  @include global.responsive(md) {
    margin-bottom: 30px;
  }
}

.p-price__about__contents__table__item__title p {
  font-weight: 400;
  font-size: global.$font-size-sm;
  color: var(--color-white);
  text-align: center;
}

.p-price__about__contents__table__item__desc {
  padding: 30px;
  text-align: center;
  @include global.responsive(lg) {
    padding: 40px;
  }
}

.p-price__about__contents__table__item__desc p:not(:last-of-type) {
  margin-bottom: 10px;
}

.p-price__about__contents__table__item__desc p span {
  font-size: 20px;
  padding-left: 15px;
}

.p-price__about__contents__inquiry {
  margin-top: 30px;
}

.p-price__about__contents__inquiry h4 {
  position: relative;
  font-size: global.$font-size-base;
  color: var(--color-primary);
  padding-left: 20px;
  margin-bottom: 20px;
}

.p-price__about__contents__inquiry h4::before {
  content: "";
  width: 15px;
  height: 15px;
  background-color: var(--color-primary);
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 6px;
}

.p-price__about__contents__inquiry span {
  padding: 4px;
}

.p-price__about__contents__inquiry__select select {
  width: 160px;
  padding: 4px;
  margin: 0 10px;
  text-align: center;
  border: 1px solid var(--color-primary);
  @include global.responsive(lg) {
    width: 240px;
  }
}

.p-price__about__contents__inquiry__select + .p-price__about__contents__inquiry__select {
  margin-top: 20px;
}

.p-price__about__contents__inquiry__select input {
  display: none;
}

.p-price__about__contents__inquiry__select label {
  display: block;
  cursor: pointer;
  padding-left: 30px;
  position: relative;
  margin-right: 10px;
  @include global.responsive(md) {
    display: inline-block;
  }
}

.p-price__about__contents__inquiry__select label::before,
.p-price__about__contents__inquiry__select label::after {
  content: "";
  display: block;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-50%);
  top: 55%;
}

.p-price__about__contents__inquiry__select label::before {
  background-color: var(--color-white);
  border: 1px solid var(--color-primary);
  height: 15px;
  width: 15px;
  left: 5px;
}

.p-price__about__contents__inquiry__select label::after {
  background-color: var(--color-primary);
  opacity: 0;
  height: 9px;
  width: 9px;
  left: 8px;
}

.p-price__about__contents__inquiry__select input:checked + label::after {
  opacity: 1;
}

.p-price__about__contents__total {
  background-color: var(--color-primary);
  padding: 20px;
  text-align: center;
  @include global.responsive(md) {
    padding: 30px;
  }
}

.p-price__about__contents__total__price {
  margin-top: 20px;
}

.p-price__about__contents__total__person h4,
.p-price__about__contents__total__price h4 {
  font-family: var(--font-family-serif);
  font-weight: 400;
  font-size: global.$font-size-lg;
  color: var(--color-white);
  margin-bottom: 5px;
  text-align: center;
}

.p-price__about__contents__total__person p,
.p-price__about__contents__total__price p {
  display: inline-block;
  font-family: var(--font-family-serif);
  font-size: global.$font-size-xxl;
  color: var(--color-white);
  text-align: center;
}

.p-price__about__contents__total__person span,
.p-price__about__contents__total__price span {
  font-size: global.$font-size-lg;
  font-family: var(--font-family-serif);
  color: var(--color-white);
  margin-left: 20px;
}