@use "../global" as global;

.p-foods__wrap {
  position: relative;
  margin-bottom: 60px;
}

.p-foods__bg {
  position: absolute;
  top: -80px;
  bottom: 0;
  width: 100%;
  @include global.responsive(md) {
    top: -140px;
  }
}

.p-foods__bg::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .3);
}

.p-foods__contents {
  position: relative;
  max-width: 1230px;
  width: 100%;
  padding: 0 15px 80px;
  margin: 0 auto;
  z-index: 3;
  @include global.responsive(sm) {
    padding: 0 40px 140px;
  }
}

.p-foods__text {
  line-height: var(--line-height-base);
  margin-bottom: 40px;
  @include global.responsive(md) {
    line-height: var(--line-height-large);
    margin-bottom: 60px;
  }
}

.p-foods__image.swiper {
  margin-top: 80px;
  margin-right: -15px;
  margin-left: -15px;
  @include global.responsive(sm) {
    margin-right: -40px;
    margin-left: -40px;
  }
  @include global.responsive(md) {
    margin-top: 100px;
  }
  @include global.responsive(xl) {
    margin-right: calc(575px - 50vw);
    margin-left: calc(575px - 50vw);
  }
}

.p-foods__slider.swiper-wrapper {
  transition-timing-function: linear;
}

.p-foods__movie {
  width: 200px;
  height: 200px;
  margin-left: auto;
  margin-top: -30px;
  @include global.responsive(md) {
    width: 260px;
    height: 260px;
    margin-top: -60px;
  }
}

.p-foods__movie a {
  position: relative;
  overflow: hidden;
}

.p-foods__movie__text {
  animation: rotate 5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  @include global.responsive(md) {
    animation: rotate 8s linear infinite;    
  }
}

.p-foods__movie__arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}