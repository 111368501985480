@use "../global" as global;

.c-btn {
    display: inline-block;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    border: none;
    font-size: 1rem;
    line-height: 1.5;
    padding: 10px 20px;
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

.c-btnWrap {
    text-align: center;
}

.c-btnLeft {
    text-align: center;
    @include global.responsive(md) {
        text-align: left;
    }
}


// btn primary

.c-btn--primary {
    @extend .c-btn;
    font-weight: 700;
    max-width: 375px;
    height: 80px;
    width: 100%;
    transition: color .5s, background-color .5s;
    color: var(--color-white);
    background-color: var(--color-secondary);
    border: 2px solid var(--color-secondary);
    line-height: 56px;
}

.c-btn--primary:hover {
    color: var(--color-secondary);
    background-color: var(--color-white);
    opacity: 1;
}

// btn primary end



// btn secondary

.c-btn--secondary {
    @extend .c-btn;
    font-weight: 700;
    max-width: 375px;
    height: 80px;
    width: 100%;
    transition: color .5s, background-color .5s;
    color: var(--color-secondary);
    border: 2px solid var(--color-secondary);
    background-color: transparent;
    line-height: 56px;
}

.c-btn--secondary:hover {
    color: var(--color-white);
    background-color: var(--color-secondary);
    opacity: 1;
}


// btn secondary end



// btn full

.c-btn--full {
    @extend .c-btn;
    font-weight: 700;
    width: 100%;
    height: 80px;
    width: 100%;
    transition: color .5s, background-color .5s;
    color: var(--color-white);
    background-color: var(--color-secondary);
    border: 2px solid var(--color-secondary);
    line-height: 56px;
    @include global.responsive(md) {
        height: 120px;
        line-height: 96px;
    }
}

.c-btn--full:hover {
    color: var(--color-secondary);
    background-color: var(--color-white);
    opacity: 1;
}

// btn full end


// btn full secondary

.c-btn--full {
    @extend .c-btn;
    font-weight: 700;
    width: 100%;
    height: 80px;
    width: 100%;
    transition: color .5s, background-color .5s;
    color: var(--color-secondary);
    border: 2px solid var(--color-secondary);
    background-color: transparent;
    line-height: 56px;
    @include global.responsive(md) {
        height: 120px;
        line-height: 96px;
    }
}

.c-btn--full:hover {
    color: var(--color-white);
    background-color: var(--color-secondary);
    opacity: 1;
}

// btn full secondary end



// main-menu--------------------------------

.c-btn--menu {
    display: block;
    border: none;
    padding: 0;
    position: relative;
    width: 48px;
    height: 48px;
    background-color: var(--bg-secondary);
    cursor: pointer;
    z-index: 11;
    border-radius: 50%;
    @include global.responsive(lg) {
        display: none;
    }
}

.c-btn--menu span {
    position: absolute;
    left: 25%;
    width: 50%;
    height: 2px;
    border-radius: 100vmax;
    background-color: var(--color-white);
    transition: .5s transform, .5s opacity, background-color .5s;
    transition-delay: 0s;
}

.c-btn--menu.is-close span:nth-of-type(1) {
    top: 15px;
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);
}

.c-btn--menu.is-close span:nth-of-type(2) {
    top: 23px;
    opacity: 1;
}

.c-btn--menu.is-close span:nth-of-type(3) {
    bottom: 15px;
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);

}

.c-btn--menu.is-open span:nth-of-type(1) {
    top: 15px;
    -webkit-transform: translateY(8px) rotate(-30deg);
    transform: translateY(8px) rotate(-30deg);
}

.c-btn--menu.is-open span:nth-of-type(2) {
    top: 23px;
    opacity: 0;
}

.c-btn--menu.is-open span:nth-of-type(3) {
    bottom: 15px;
    -webkit-transform: translateY(-8px) rotate(30deg);
    transform: translateY(-8px) rotate(30deg);
}
