@use "../global" as global;

.p-cancel {
  margin-bottom: 40px;
  @include global.responsive(md) {
    margin-bottom: 80px;
  }
}

.p-cancel__contents {
  padding: 20px 15px;
  border: 1px solid var(--color-primary);
  @include global.responsive(md) {
    padding: 60px;
  }
}

.p-cancel__price {
  margin-bottom: 32px;
}

.p-cancel__title {
  font-family: var(--font-family-serif);
  font-size: global.$font-size-xl;
  color: var(--color-primary);
  margin-bottom: 16px;
}

.p-cancel__contents ol,
.p-cancel__contents ul {
  padding-left: 20px;
  margin-top: 5px;
  margin-bottom: 20px;
  @include global.responsive(md) {
    margin-bottom: 10px;
  }
}

.p-cancel__contents ol li,
.p-cancel__contents ul li,
.p-cancel__contents p {
  font-weight: 400;
}
