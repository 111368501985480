@use "../global" as global;

.p-access {
  padding: 80px 0;
  @include global.responsive(md) {
    padding: 140px 0;
  }
}

.p-access__info {
  @include global.responsive(lg) {
    display: flex;
    column-gap: 40px;
  }
}

.p-access__map {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  @include global.responsive(lg) {
    width: 55%;
    padding-top: 40%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.p-access__list {
  width: 100%;
  margin-bottom: 40px;
  @include global.responsive(lg) {
    width: calc(45% - 40px);
    margin-bottom: 0;
  }
}

.p-access__item {
  display: flex;
  padding: 20px 5px;
  border-bottom: 1px solid var(--color-primary);
  
  strong {
    display: block;
    margin-bottom: 5px;
  }
  
  @include global.responsive(sm) {
    padding: 20px 10px;
  }

  &:last-of-type {
    border-bottom: none;
  }
}

.p-access__item__icon {
  width: 20%;
  text-align: center;
  img {
    vertical-align: middle;
  }
}

.p-access__item__desc {
  width: 80%;
  font-weight: 400;
}