@use "../global" as global;

.p-news__note.swiper {
  margin-right: -15px;
  padding-right: 15px;
  padding-top: 60px;
  margin-top: -60px;
  @include global.responsive(sm) {
    margin-right: -40px;
    padding-right: 40px;
  }
  @include global.responsive(md) {
    padding-top: 120px;
    margin-top: -120px;
  }
  @include global.responsive(xl) {
    margin-right: calc(575px - 50vw);
  }
}

.p-news__note__image {
  border: 4px solid var(--color-white);
}

.p-news__note__time {
  font-size: global.$font-size-base;
  color: var(--color-primary);
  margin-top: 8px;
}

.p-news__note__title {
  font-size: global.$font-size-base;
  margin-top: 5px;
}

.p-news__note__nav {
  display: flex;
  column-gap: 20px;
  position: absolute;
  top: 0;
  right: 20px;
  @include global.responsive(md) {
    column-gap: 40px;
    right: 100px;
  }
}

.p-news__note__nav .swiper-button-next,
.p-news__note__nav .swiper-button-prev {
  position: relative;
  margin-top: 0;
}

.p-news__note__nav .swiper-button-next {
  right: 0;
  left: 0;
  width: 48px;
  height: 48px;
  @include global.responsive(md) {
    width: 70px;
    height: 70px;
  }
  &::after {
    content: url(../images/index/next_black.svg);
    width: 100%;
    height: 100%;
  }
}

.p-news__note__nav .swiper-button-prev {
  right: 0;
  left: 0;
  width: 48px;
  height: 48px;
  @include global.responsive(md) {
    width: 70px;
    height: 70px;
  }
  &::after {
    content: url(../images/index/prev_black.svg);
    width: 100%;
    height: 100%;
  }
}