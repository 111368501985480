@use "../global" as global;

.p-head {
  position: relative;
  margin-bottom: 40px;
  @include global.responsive(sm) {
    margin-bottom: 80px;
  }
}

.p-head__bg {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.p-head__bg::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .2);
}

.p-head__contents {
  position: relative;
  z-index: 3;
  padding: 200px 15px 20px;
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  @include global.responsive(sm) {
    padding: 300px 40px 40px;
  }

  h1 {
    font-family: var(--font-family-original);
    font-size: global.$font-size-xxxl;
    color: var(--color-white);
    text-transform: uppercase;
    line-height: 1.2;
    overflow: hidden;
  }

  p {
    font-family: var(--font-family-serif);
    font-size: global.$font-size-xl;
    color: var(--color-white);
    overflow: hidden;
  }

  h1 span,
  p span {
    display: inline-block;
    transform: translateY(100%);
  }

  h1.js-active span,
  p.js-active span {
    animation-name: show;
    animation-timing-function: ease-out;
    animation-duration: 0.75s;
    animation-fill-mode: forwards;
  }
}