@use "../global" as global;

.p-time {
  position: relative;
}

.p-time__bg.swiper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
}

.p-time__bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, .3);
}

.p-time__slider,
.p-time__slider__item {
  height: 100%;
}

.p-time__slider__item.swiper-slide-active img,
.p-time__slider__item.swiper-slide-duplicate-active img,
.p-time__slider__item.swiper-slide-prev img {
  animation: zoom 18s linear 0s 1 normal both;
}

.p-time__contents {
  position: relative;
  padding: 80px 15px;
  z-index: 3;
  @include global.responsive(sm) {
    padding: 80px 40px;
  }
  @include global.responsive(md) {
    padding: 140px 40px;
  }
}

.p-time__text {
  color: var(--color-white);
  line-height: var(--line-height-base);
  margin-bottom: 40px;
  @include global.responsive(sm) {
    line-height: var(--line-height-large);
    margin-bottom: 60px;
  }
}


// time 下層ページ

.p-time__flow {
  position: relative;
}

.p-time__flow::before {
  content: "";
  width: 2px;
  position: absolute;
  top: 90px;
  left: 22px;
  bottom: 60vw;
  background-color: var(--color-white);
  z-index: 4;
  @include global.responsive(sm) {
    bottom: 32vw;
  }
  @include global.responsive(md) {
    bottom: 33vw;
  }
  @include global.responsive(lg) {
    left: 44px;
    bottom: 22vw;
  }
  @include global.responsive(lg) {
    bottom: 310px;
  }
}


.p-time__flow__item {
  position: relative;
  height: 70vw;
  padding: 15px 40px;
  @include global.responsive(sm) {
    height: 40vw;
  }
  @include global.responsive(lg) {
    height: 30vw;
    padding: 40px 80px;
  }
  @include global.responsive(xl) {
    height: 400px;
  }
}

.p-time__flow__item + .p-time__flow__item {
  margin-top: 40px;
  @include global.responsive(md) {
    margin-top: 60px;
  }
}

.p-time__flow__item::before {
  content: "";
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #c1c1c100 0%, #606060d5 100%);
  z-index: 2;
}

.p-time__flow__item__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.p-time__flow__item__meta {
  position: relative;
  z-index: 3;
}

.p-time__flow__item__time {
  position: relative;
  color: var(--color-white);
  font-family: var(--font-family-serif);
  font-size: global.$font-size-xxxl;
}

.p-time__flow__item__time::before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: calc(50% - 5px);
  left: -22px;
  background-color: var(--color-white);
  border-radius: 50%;
  @include global.responsive(lg) {
    left: -40px;
  }
}

.p-time__flow__item__title {
  color: var(--color-white);
  font-family: var(--font-family-serif);
  font-size: global.$font-size-lg;
}

.p-time__flow__item__desc {
  color: var(--color-white);
  position: absolute;
  left: 40px;
  right: 15px;
  bottom: 20px;
  z-index: 3;
  @include global.responsive(lg) {
    left: 80px;
    right: 80px;
    bottom: 40px;
  }
}