@use "../global" as global;

.c-header {
  width: 100%;
  position: fixed;
  top: 20px;
  left: 0;
  z-index: 10;
  @include global.responsive(lg) {
    display: block;
    top: 0;
    background-color: var(--bg-base);
    border-bottom: 1px solid var(--color-primary);
  }
}

.c-header__contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  @include global.responsive(md) {
    padding: 20px 40px;
  }
}

.c-header__logo {
  width: 90px;
  z-index: 11;
  svg {
    fill: var(--color-white);
    width: 100%;
    height: auto;
    transition: fill .5s;
    @include global.responsive(lg) {
      fill: var(--color-base);
    }
  }
}

.c-header.is-hide .c-header__logo {
  @include global.responsive(lg,true) {
    opacity: 0;
    pointer-events: none;
  }
}

.c-header.is-hide.is-open .c-header__logo {
  opacity: 1;
}

.c-header.is-open .c-header__logo svg {
  fill: var(--color-white);
}

.c-header__nav {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: -20px;
  left: 0;
  background-color: var(--bg-secondary);
  padding: 20vh 20px;
  opacity: 0;
  transition: opacity .5s;
  pointer-events: none;
  text-align: center;
  overflow: auto;

  @include global.responsive(lg) {
    display: flex;
    align-items: center;
    height: auto;
    width: auto;
    position: relative;
    top: 0;
    column-gap: 20px;
    background-color: transparent;
    opacity: 1;
    pointer-events: all;
    padding: 0;
    text-align: left;
    overflow: initial;
  }
  @include global.responsive(xl) {
    column-gap: 40px;
  }
}

.c-header.is-open .c-header__nav {
  pointer-events: all;
  opacity: 1;
}

.c-header__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 40px;
  margin-bottom: 50px;
  text-align: center;
  @include global.responsive(lg) {
    align-items: normal;
    column-gap: 20px;
    margin-bottom: 0;
    text-align: left;
  }
  @include global.responsive(xl) {
    column-gap: 40px;
  }
}

.c-header__item {
  width: 100%;
  @include global.responsive(lg) {
    width: auto;
  }
  a {
    font-size: 16px;
    font-weight: 700;
    color: var(--color-white);
    padding: 5px 0;
    border: none;
    background: linear-gradient(var(--color-primary) 0 0) var(--p, 0) 100% /var(--d, 0) 1.5px no-repeat;
    transition: 0.3s, background-position 0s 0.3s;
    &:hover,
    &:focus {
      --d: 100%;
      --p: 100%;
    }
    @include global.responsive(lg) {
      color: var(--color-base);
      font-size: 14px;
    }
    @include global.responsive(xl) {
      font-size: 16px;
    }
  }
} 

.c-header__btn {
  a {
    display: block;
    max-width: 400px;
    margin: 0 auto;
    width: 100%;
    font-size: 16px;
    color: var(--color-white);
    padding: 30px 40px;
    position: relative;
    background-color: var(--color-secondary);
    border: 2px solid var(--color-secondary);
    transition: .5s;
    &:hover,
    &:focus {
      opacity: 1;
      color: var(--color-secondary);
      background-color: var(--color-white);
    }
    @include global.responsive(lg) {
      display: inline;
      width: auto;
      padding: 10px 30px;
      font-size: 14px;
    }
    @include global.responsive(xl) {
      font-size: 16px;
    }
  }
}