@use "../global" as global;

.l-section.--bg {
  padding: 80px 0;
  background-color: var(--bg-primary);
  @include global.responsive(md) {
    padding: 140px 0;
  }
}

.l-section__inner {
  max-width: 1230px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  @include global.responsive(sm) {
    padding: 0 40px;
  }
}