@use "../global" as global;

.p-about__contents {
  @include global.responsive(lg) {
    display: flex;
    flex-direction: row-reverse;
    column-gap: 60px;
  }
}

.p-about__image.swiper {
  width: 100%;
  margin-bottom: 40px;
  @include global.responsive(sm) {
    width: 60%;
    min-width: 500px;
    margin: 0 auto 60px;
  }
  @include global.responsive(lg) {
    min-width: auto;
    width: calc(40% + 50px);
    margin-top: 20px;
    margin-right: -50px;
    margin-bottom: 0;
  }
  @include global.responsive(xl) {
    width: calc(50% + 100px);
    margin-right: -100px;    
  }
}

.p-about__desc {
  @include global.responsive(lg) {
    width: calc(60% - 60px);
  }
  @include global.responsive(xl) {
    width: calc(50% - 60px);
  }
}

.p-about__desc__text {
  line-height: var(--line-height-base);
  @include global.responsive(md) {
    line-height: var(--line-height-large);
  }
}

.p-about__view {
  position: relative;
}

.p-about__view__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.p-about__view__inner {
  max-width: 1230px;
  width: 100%;
  padding: 40vw 15px 20vw;
  margin: 0 auto;
  @include global.responsive(sm) {
    padding: 40vw 40px 20vw;
  }
  @include global.responsive(md) {
    text-align: right;
    padding: 20vw 40px 10vw;
  } 
}

.p-about__view__text {
  display: inline-block;
  position: relative;
  color: var(--color-white);
  line-height: var(--line-height-base);
  z-index: 2;
  @include global.responsive(md) {
    text-align: left;
    line-height: var(--line-height-large);
  }
}