@use "../global" as global;

.c-footer {
  background-color: var(--color-base);
}

.c-footer__contents {
  width: 100%;
  padding: 120px 15px 0;
  margin-bottom: 40px;
  @include global.responsive(md) {
    padding: 80px 40px 0;
    margin-bottom: 10px;
    display: grid;
    row-gap: 50px;
    grid-template-areas:
      "first third"
      "seconde third";  
  } 
  @include global.responsive(lg) {
    grid-template-areas:
      "first seconde"
      "third third";
  }
}

.c-footer__logo {
  max-width: 240px;
  width: 60%;
  margin-bottom: 40px;
  @include global.responsive(md) {
    grid-area: first;
    margin-bottom: 0;
  } 

  svg {
    width: 100%;
    height: auto;
    fill: var(--color-white);
  }
}

.c-footer__sns {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  @include global.responsive(md) {
    justify-content: start;
    grid-area: seconde;
    align-self: end;
  } 
  @include global.responsive(lg) {
    align-self: center;
    justify-self: end;
  } 

  p {
    font-family: var(--font-family-original);
    text-transform: uppercase;
    color: var(--color-white);
  }
}

.c-footer__sns__list {
  display: flex;
  column-gap: 20px;
}

.c-footer__sns__item a {
  padding: 16px;
  border: 1px solid var(--color-white);
  border-radius: 50%;
  transition: .5s all;

  svg {
    fill:var(--color-white);
  }

  &:hover,
  &:focus {
    background-color: var(--color-white);
    svg {
      fill:var(--color-base);
    }
  }
}

.c-footer__nav {
  margin-bottom: 60px;
  @include global.responsive(md) {
    grid-area: third;
    margin-bottom: 0;
    text-align: right;
  } 
  @include global.responsive(lg) {
    text-align: left;
    display: flex;
    column-gap: 40px;
    justify-content: space-between;
  } 
}

.c-footer__list {
  margin-bottom: 30px;
  @include global.responsive(lg) {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    margin-bottom: 0;
  }
  @include global.responsive(xl) {
    column-gap: 40px;
  }
}

.c-footer__item {
  margin-bottom: 15px;
  @include global.responsive(lg) {
    margin-bottom: 0;
  }
}

.c-footer__item a {
  font-size: global.$font-size-base;
  font-weight: 700;
  color: var(--color-white);
  padding: 5px 0;
  border: none;
  background: linear-gradient(var(--color-white) 0 0) var(--p, 0) 100% /var(--d, 0) 1.5px no-repeat;
  transition: 0.3s, background-position 0s 0.3s;
  &:hover,
  &:focus {
    --d: 100%;
    --p: 100%;
  }
}

.c-footer__btn {
  a {
    display: inline-block;
    width: 100%;
    color: var(--color-white);
    padding: 30px 40px;
    position: relative;
    background-color: var(--color-secondary);
    border: 2px solid var(--color-secondary);
    transition: .5s;
    text-align: center;
    
    @include global.responsive(sm) {
      padding: 10px 40px;
      width: auto;
    }

    &:hover,
    &:focus {
      opacity: 1;
      color: var(--color-secondary);
      background-color: var(--color-white);
    }
  }
}

.c-footer__copyright {
  padding: 20px 0;
  p {
    color: var(--color-white);
    font-size: 12px;
    font-family: var(--font-family-sans-serif);
    text-align: center;
    letter-spacing: 0;
  }
}
