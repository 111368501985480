@use "../global" as global;

.c-title {
	margin-bottom: 40px;
	@include global.responsive(md) {
		margin-bottom: 60px;
	}
}

.c-title__main {
	font-family: var(--font-family-original);
	font-size: global.$font-size-xxxl;
	line-height: 1.2;
	color: var(--color-primary);
	overflow: hidden;
}

.c-title__sub {
	font-size: global.$font-size-lg;
	font-family: var(--font-family-serif);
	font-weight: 900;
	color: var(--color-primary);
	overflow: hidden;
}

.c-title__main span,
.c-title__sub span {
	display: inline-block;
	transform: translateY(100%);
}

.c-title__main.js-active span,
.c-title__sub.js-active span {
	animation-name: show;
	animation-timing-function: ease-out;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
}

.c-title.--secondary {
	.c-title__main,
	.c-title__sub {
		color: var(--color-white);
	}
}

.c-title--heading {
	font-size: global.$font-size-xxl;
	font-family: var(--font-family-original);
	color: var(--color-primary);
	margin: 40px 0;
	@include global.responsive(md) {
		margin: 80px 0;
	}
}

.c-title--heading.--secondary {
	color: var(--color-white);
}