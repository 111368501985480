@use "../global" as global;

.p-stay {
  padding: 80px 0;
  @include global.responsive(md) {
    padding: 140px 0;
  }
}

.p-stay__type.swiper {
  margin-bottom: 40px;
  margin-right: -15px;
  margin-left: -15px;
  @include global.responsive(sm) {
    margin-bottom: 60px;
    margin-right: -40px;
    margin-left: -40px;
  }
  @include global.responsive(xl) {
    margin-right: calc(575px - 50vw);
    margin-left: calc(575px - 50vw);
  }
}

.p-stay__type .swiper-button-prev {
  left: 8%;
  width: 48px;
  height: 48px;
  @include global.responsive(md) {
    width: 54px;
    height: 54px;
  }
  @include global.responsive(xl) {
    width: 70px;
    height: 70px;
  }
  &::after {
    content: url(../images/index/prev.svg);
    width: 100%;
  }
}

.p-stay__type .swiper-button-next {
  right: 8%;
  width: 48px;
  height: 48px;
  @include global.responsive(md) {
    width: 54px;
    height: 54px;
  }
  @include global.responsive(xl) {
    width: 70px;
    height: 70px;
  }
  &::after {
    content: url(../images/index/next.svg);
    width: 100%;
  }
}

.p-stay__slider__item a {
  position: relative;
}

.p-stay__slider__item a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .3);
  pointer-events: none;
}

.p-stay__type__desc {
  position: absolute;
  left: 15px;
  bottom: 15px;
  @include global.responsive(md) {
    left: 40px;
    bottom: 40px;
  }

  h4 {
    font-family: var(--font-family-serif);
    font-size: global.$font-size-xxl;
    color: var(--color-white);
  }

  p {
    font-family: var(--font-family-serif);
    color: var(--color-white);
  }
}

.p-stay__text {
  line-height: var(--line-height-base);
  margin-bottom: 40px;
  @include global.responsive(md) {
    margin-bottom: 60px;
    line-height: var(--line-height-large);
  }
}



// stay 下層ページ


.p-stay--section {
  padding-top: 80px;
  @include global.responsive(md) {
    padding-top: 120px;
  }
}

.p-stay__link {
  max-width: 1000px;
  margin: 40px auto 0;
}

.p-stay__link__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 20px;
}

.p-stay__link__item {
  width: 100%;
  text-align: center;
  border: 2px solid var(--color-primary);
  @include global.responsive(lg) {
    width: calc(33.3333% - 26.6666px);
  }
}

.p-stay__link__item a {
  display: block;
  padding: 20px 0;
  line-height: 1.4;
}

.p-stay__details {
  @include global.responsive(lg) {
    display: flex;
    column-gap: 40px;
    padding: 0;
  }
  @include global.responsive(xl) {
    padding: 0 60px;
  }
}

.p-stay__details > p {
  line-height: var(--line-height-base);
  width: 100%;
  margin-bottom: 40px;
  @include global.responsive(lg) {
    margin-bottom: 0;
    width: calc(100% - 400px);
    line-height: var(--line-height-large);
  }
}

.p-stay__details__spec {
  background-color: var(--color-primary);
  color: var(--color-white);
  padding: 20px;
  width: 100%;
  @include global.responsive(lg) {
    width: 360px;
    padding: 20px;
  }
  h4 {
    font-family: var(--font-family-serif);
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--color-white);
  }
  p {
    strong {
      display: inline-block;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    font-weight: 400;
    color: var(--color-white);
  }
}

.p-stay__images--carryon.swiper,
.p-stay__images--rental.swiper,
.p-stay__images--cottage.swiper {
  margin-bottom: 20px;
}

.p-stay__images--carryon .swiper-pagination,
.p-stay__images--rental .swiper-pagination,
.p-stay__images--cottage .swiper-pagination {
  position: relative;
  margin-top: 20px;
  @include global.responsive(md) {
    margin-top: 30px;
  }
}

.p-stay__images--carryon .swiper-pagination.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet,
.p-stay__images--rental .swiper-pagination.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet,
.p-stay__images--cottage .swiper-pagination.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  margin: 0 10px;
  background-color: transparent;
  border: 1px solid var(--color-primary);
  opacity: 1;
}

.p-stay__images--carryon .swiper-pagination.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active,
.p-stay__images--rental .swiper-pagination.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active,
.p-stay__images--cottage .swiper-pagination.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}
