@use "../global" as global;

.p-mv {
  position: relative;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  margin-bottom: 60px;
  overflow: hidden;
  @include global.responsive(lg) {
    margin-bottom: 100px;
  }
}

.p-mv__movie {
  position: relative;
  width: 100%;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../images/index/bg_effect.png) repeat;
    opacity: .4;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bg-secondary);
    opacity: .2;
  }
}

.p-mv__contents {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.p-mv__logo {
  max-width: 142px;
  width: 40%;
  margin: 0 auto 30px;

  @include global.responsive(md) {
    width: 100%;
    margin: 0 auto 40px;
  }

  svg {
    width: 100%;
    height: auto;
    fill: #fff;
  }
}

.p-mv__title {
  display: inline-block;
  font-family: var(--font-family-original);
  font-size: global.$font-size-lg;
  color: var(--color-white);  

  & .br-sm {
    display: block;
    @include global.responsive(md) {
      display: none;
    }
  }
}