@use "../global" as global;

.p-recommened__list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 40px;
  @include global.responsive(sm) {
    column-gap: 20px;
    row-gap: 20px;
  }
  @include global.responsive(lg) {
    column-gap: 40px;
    row-gap: 40px;
  }
}

.p-recommened__item {
  width: 100%;
  @include global.responsive(sm) {
    width: calc(50% - 20px);
  }
  @include global.responsive(lg) {
    width: calc(33.3333% - 26.6666px);
  }
}

.p-recommened__item a {
  display: block;
  height: 100%;
  background-color: var(--color-white);
  padding: 15px;
  transition: .5s transform, .5s opacity;
  @include global.responsive(sm) {
    padding: 20px;
  }
}

.p-recommened__item a:hover, 
.p-recommened__item a:focus {
  transform: translate(5px, 5px);
}

.p-recommened__item__title {
  margin-top: 10px;
  font-size: global.$font-size-xl;
  font-family: var(--font-family-original);
  color: var(--color-primary);
}

.p-recommened__item__desc {
  margin-top: 4px;
  font-weight: 500;
}

.p-recommend__info {
  margin-top: 10px;
}

.p-recommend__info__icon {
  width: 20px;
  text-align: center;
  margin-right: 10px;
}

.p-recommend__info__desc {
  font-size: global.$font-size-sm;
  font-weight: 400;
}

.p-recommend__access,
.p-recommend__time {
  display: flex;
  align-items: center;
}

.p-recommend__time {
  margin-top: 5px;
}