@use "../global" as global;

.c-media,
.c-media--md,
.c-media--lg {
    position: relative;
    z-index: 0;
    width: 100%;
    overflow: hidden;
    -webkit-transform: perspective(0);
    transform: perspective(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.c-media::before {
    display: block;
    height: 0;
    padding-top: 56.25%;
    content: "";
}

.c-media--md::before {
    display: block;
    height: 0;
    padding-top: 75%;
    content: "";
}

.c-media--lg::before {
    display: block;
    height: 0;
    padding-top: 100%;
    content: "";
}

.c-media__item {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    object-position: center;
    font-family: "object-fit: cover;";
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: scale(1.01) perspective(0);
    transform: scale(1.01) perspective(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s;
}
